"use client"

import { useState } from "react"

export default function SalaryCalculatorSections() {
    const [openFaqIndex, setOpenFaqIndex] = useState(null)

    // Brand colors and reusable styles
    const styles = {
        colors: {
            primary: "#4F46E5",
            background: "#f9fafb",
            text: "#374151",
            textLight: "#6b7280",
            border: "#e5e7eb",
        },
        container: {
            padding: "32px 24px",
            maxWidth: "1100px",
            margin: "0 auto",
        },
        heading: {
            fontSize: "24px",
            fontWeight: "600",
            marginTop: 0,
            marginBottom: "16px",
            textAlign: "center",
        },
        subheading: {
            fontSize: "16px",
            color: "#6b7280",
            marginTop: 0,
            marginBottom: "24px",
            textAlign: "center",
        },
        grid: {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
            gap: "20px",
        },
        card: {
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        },
        stepNumber: {
            width: "36px",
            height: "36px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "16px",
            fontWeight: "bold",
            flexShrink: 0,
        },
        iconContainer: {
            width: "48px",
            height: "48px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "16px",
        },
    }

    // Reviews data (6 reviews)
    const reviews = [
        {
            name: "David Chen",
            role: "Software Engineer @ Google",
            rating: 5,
            comment:
                "This salary calculator gave me the confidence to negotiate a 15% higher offer than I initially received. The industry trends section was particularly helpful.",
        },
        {
            name: "Priya Sharma",
            role: "Product Manager @ Amazon",
            rating: 5,
            comment:
                "I was able to benchmark my compensation against industry standards and realized I was being underpaid. Used the data to secure a significant raise!",
        },
        {
            name: "James Wilson",
            role: "Data Scientist @ Microsoft",
            rating: 4,
            comment:
                "The breakdown of base salary vs bonuses helped me understand the full compensation package. Very useful for comparing multiple offers.",
        },
        {
            name: "Maria Rodriguez",
            role: "UX Designer @ Adobe",
            rating: 5,
            comment:
                "As someone switching careers, I had no idea what salary to expect. This tool gave me realistic ranges that helped me prepare for interviews.",
        },
        {
            name: "Alex Johnson",
            role: "Marketing Director @ Spotify",
            rating: 5,
            comment:
                "The location-specific data was spot on! I was relocating to a new city and needed to understand how my compensation should adjust.",
        },
        {
            name: "Michael Patel",
            role: "DevOps Engineer @ Netflix",
            rating: 5,
            comment:
                "Used this before my annual review and was able to make a data-backed case for why I deserved a raise. It worked!",
        },
    ]

    // FAQs data
    const faqs = [
        {
            question: "How accurate is the AI Salary Calculator?",
            answer: "Our calculator uses up-to-date data from industry sources and is regularly updated to reflect current market conditions. While no estimate can be 100% precise, our tool provides reliable salary ranges that account for factors like location, experience level, and industry.",
        },
        {
            question: "What factors influence the salary estimate?",
            answer: "The calculator considers your job title, experience level, location, and industry to generate estimates. These factors significantly impact compensation packages across different markets and companies.",
        },
        {
            question: "Can I use this data for salary negotiations?",
            answer: "Many users leverage our salary estimates during job negotiations or performance reviews. The detailed breakdown of base salary, bonuses, and total compensation provides valuable talking points backed by market data.",
        },
        {
            question: "How often is the salary data updated?",
            answer: "We update our underlying data monthly to ensure you receive the most current salary information. The AI model is also regularly fine-tuned to improve accuracy and reflect changing market conditions.",
        },
        {
            question: "Does the calculator account for company size?",
            answer: "While the calculator doesn't explicitly ask for company size, the estimates typically reflect a range that spans both smaller companies and larger enterprises. The results often mention how company size can impact compensation.",
        },
        {
            question: "Can I save or share my salary results?",
            answer: "Yes! After generating your salary estimate, you can copy the results to your clipboard with a single click. This makes it easy to save the information or share it with others via email or messaging apps.",
        },
    ]

    // Features data (6 features)
    const features = [
        {
            icon: (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                    <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                    <line x1="12" y1="22.08" x2="12" y2="12"></line>
                </svg>
            ),
            title: "Comprehensive Data",
            description:
                "Get complete compensation insights including base salary, bonuses, and benefits.",
        },
        {
            icon: (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <circle cx="12" cy="12" r="10"></circle>
                    <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"></polygon>
                </svg>
            ),
            title: "Location-Specific",
            description:
                "Tailored salary ranges based on your location and regional cost of living factors.",
        },
        {
            icon: (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
            ),
            title: "AI-Powered Insights",
            description:
                "Advanced AI analyzes thousands of data points to provide accurate, up-to-date salary information.",
        },
        {
            icon: (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                </svg>
            ),
            title: "Industry Trends",
            description:
                "Stay informed about the latest compensation trends and factors affecting your role.",
        },
        {
            icon: (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                    ></rect>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                </svg>
            ),
            title: "Negotiation Tool",
            description:
                "Use our data-backed insights to confidently negotiate better compensation packages.",
        },
        {
            icon: (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
            ),
            title: "Career Planning",
            description:
                "Make informed decisions about job changes, promotions, and long-term career growth.",
        },
    ]

    // How to use steps
    const steps = [
        {
            title: "Enter Your Job Title",
            description:
                "Specify the exact role you're researching to get the most accurate salary data.",
        },
        {
            title: "Select Experience Level",
            description:
                "Choose between Junior, Mid-Level, or Senior to refine your salary estimate.",
        },
        {
            title: "Add Location (Optional)",
            description:
                "Specify your location for region-specific salary data that accounts for cost of living differences.",
        },
        {
            title: "Select Industry (Optional)",
            description:
                "Different industries offer varying compensation for similar roles. Specify yours for more accurate results.",
        },
        {
            title: "Get Your Salary Estimate",
            description:
                'Click "Calculate Salary" to receive a comprehensive breakdown of base salary, bonuses, and industry trends.',
        },
    ]

    return (
        <div
            style={{
                fontFamily: "Inter, sans-serif",
                color: styles.colors.text,
                backgroundColor: "white",
                borderRadius: "8px",
                overflow: "hidden",
            }}
        >
            {/* How to Use Section */}
            <section style={{ ...styles.container, backgroundColor: "white" }}>
                <h2 style={{ ...styles.heading, color: styles.colors.primary }}>
                    How to Use Our Salary Calculator
                </h2>

                <div style={{ maxWidth: "800px", margin: "0 auto" }}>
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                marginBottom:
                                    index === steps.length - 1 ? 0 : "24px",
                            }}
                        >
                            <div
                                style={{
                                    ...styles.stepNumber,
                                    backgroundColor: styles.colors.primary,
                                    color: "white",
                                }}
                            >
                                {index + 1}
                            </div>
                            <div style={{ marginLeft: "16px" }}>
                                <h3
                                    style={{
                                        margin: "0 0 4px 0",
                                        fontSize: "18px",
                                        fontWeight: "600",
                                    }}
                                >
                                    {step.title}
                                </h3>
                                <p
                                    style={{
                                        margin: 0,
                                        color: styles.colors.textLight,
                                        fontSize: "14px",
                                    }}
                                >
                                    {step.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Features Section */}
            <section
                style={{
                    ...styles.container,
                    backgroundColor: styles.colors.background,
                }}
            >
                <h2 style={styles.heading}>
                    Why Use Our AI Salary Calculator?
                </h2>
                <p style={styles.subheading}>
                    Make informed career decisions with accurate, up-to-date
                    salary insights
                </p>

                <div
                    style={{
                        ...styles.grid,
                        gridTemplateColumns:
                            "repeat(auto-fit, minmax(250px, 1fr))",
                    }}
                >
                    {features.map((feature, index) => (
                        <div key={index} style={styles.card}>
                            <div
                                style={{
                                    ...styles.iconContainer,
                                    backgroundColor: "#ede9fe",
                                    color: styles.colors.primary,
                                    margin: "0 auto 16px",
                                }}
                            >
                                {feature.icon}
                            </div>
                            <h3
                                style={{
                                    fontSize: "18px",
                                    margin: "0 0 8px 0",
                                    fontWeight: "600",
                                    textAlign: "center",
                                }}
                            >
                                {feature.title}
                            </h3>
                            <p
                                style={{
                                    color: styles.colors.textLight,
                                    fontSize: "14px",
                                    margin: 0,
                                    textAlign: "center",
                                }}
                            >
                                {feature.description}
                            </p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Reviews Section */}
            <section style={{ ...styles.container, backgroundColor: "white" }}>
                <h2 style={{ ...styles.heading, color: styles.colors.primary }}>
                    20,000+ Professionals Trust Our Salary Data
                </h2>
                <p style={styles.subheading}>
                    See what they say about our AI Salary Calculator
                </p>

                <div
                    style={{
                        ...styles.grid,
                        gridTemplateColumns:
                            "repeat(auto-fit, minmax(300px, 1fr))",
                    }}
                >
                    {reviews.map((review, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundColor: styles.colors.background,
                                borderRadius: "8px",
                                padding: "20px",
                            }}
                        >
                            <h3
                                style={{
                                    color: styles.colors.primary,
                                    fontSize: "16px",
                                    margin: "0 0 4px 0",
                                    fontWeight: "600",
                                }}
                            >
                                {review.name}
                            </h3>
                            <p
                                style={{
                                    color: styles.colors.textLight,
                                    fontSize: "12px",
                                    margin: "0 0 8px 0",
                                }}
                            >
                                {review.role}
                            </p>
                            <div
                                style={{
                                    color: "#f59e0b",
                                    marginBottom: "8px",
                                }}
                            >
                                {Array(5)
                                    .fill(0)
                                    .map((_, i) => (
                                        <span key={i}>
                                            {i < review.rating ? "★" : "☆"}
                                        </span>
                                    ))}
                            </div>
                            <p
                                style={{
                                    fontSize: "14px",
                                    margin: 0,
                                    lineHeight: "1.5",
                                }}
                            >
                                {review.comment}
                            </p>
                        </div>
                    ))}
                </div>
            </section>

            {/* FAQ Section */}
            <section
                style={{
                    ...styles.container,
                    backgroundColor: styles.colors.background,
                }}
            >
                <h2 style={{ ...styles.heading, color: styles.colors.primary }}>
                    Frequently Asked Questions
                </h2>
                <p style={styles.subheading}>
                    Everything you need to know about our AI Salary Calculator
                </p>

                <div style={{ maxWidth: "800px", margin: "0 auto" }}>
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundColor: "white",
                                borderRadius: "8px",
                                padding: "16px",
                                marginBottom:
                                    index === faqs.length - 1 ? 0 : "12px",
                                cursor: "pointer",
                                transition: "all 0.2s ease",
                            }}
                            onClick={() =>
                                setOpenFaqIndex(
                                    openFaqIndex === index ? null : index
                                )
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: styles.colors.primary,
                                            marginRight: "12px",
                                        }}
                                    >
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <circle cx="12" cy="12" r="10" />
                                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                            <line
                                                x1="12"
                                                y1="17"
                                                x2="12.01"
                                                y2="17"
                                            />
                                        </svg>
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "15px",
                                        }}
                                    >
                                        {faq.question}
                                    </span>
                                </div>
                                <span style={{ color: styles.colors.primary }}>
                                    {openFaqIndex === index ? (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <polyline points="18 15 12 9 6 15" />
                                        </svg>
                                    ) : (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <polyline points="6 9 12 15 18 9" />
                                        </svg>
                                    )}
                                </span>
                            </div>

                            {openFaqIndex === index && (
                                <p
                                    style={{
                                        marginTop: "12px",
                                        color: styles.colors.textLight,
                                        paddingLeft: "32px",
                                        fontSize: "14px",
                                        lineHeight: "1.5",
                                    }}
                                >
                                    {faq.answer}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </section>

            {/* Contact Section */}
            <div
                style={{
                    borderTop: `1px solid ${styles.colors.border}`,
                    padding: "20px",
                    textAlign: "center",
                    backgroundColor: "white",
                }}
            >
                <p
                    style={{
                        margin: "0 0 4px 0",
                        fontSize: "14px",
                        color: styles.colors.textLight,
                    }}
                >
                    Questions or feedback? Email me!
                </p>
                <a
                    href="mailto:ben@interviewsidekick.com"
                    style={{
                        display: "inline-block",
                        backgroundColor: "#f3f4f6",
                        padding: "8px 16px",
                        borderRadius: "6px",
                        textDecoration: "none",
                        color: styles.colors.primary,
                        fontWeight: "500",
                        fontSize: "14px",
                    }}
                >
                    ben@interviewsidekick.com
                </a>
            </div>
        </div>
    )
}
